@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  padding-block: 170px 112px;

  @include media(mobile-l) {
    padding-block: 120px 50px;
  }

  &__wrapper {
    @include flex-col;
    align-items: center;
    text-align: center;
  }

  h1 {
    max-width: 1010px;
    width: 100%;
    line-height: 1.17;
    margin-bottom: 16px;
  }

  p {
    max-width: 600px;
    width: 100%;
    font-size: 18px;
    margin-bottom: 50px;
  }

  &__image {
    img {
      width: 100%;
      aspect-ratio: 97/50;

      @include media(mobile-l) {
        aspect-ratio: 16/10;
      }
    }
  }
}