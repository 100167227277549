@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.offer {
  @include flex-h-center;
  flex-direction: column;
  background-color: var(--color-blue-100);
  height: 100%;
  padding: 24px;
  gap: 50px;

  @include media(mobile-s) {
    padding: 15px;
  }

  h3 {
    line-height: 1.2;
    font-size: 32px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
  }

  span {
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    color: var(--color-dark-500);
    font-weight: var(--fw-600);
    white-space: nowrap;
  }

  &__content {
    @include flex-col;
    gap: 25px;
  }

  p {
    font-size: 20px;
  }

  &--home {
    p {
      font-size: 32px;
      line-height: 1.2;
      color: var(--color-dark-900);
      font-weight: var(--fw-600);

      @include media(tablet) {
        font-size: 26px;
      }

      @include media(mobile-m) {
        font-size: 22px;
      }
    }
  }
}