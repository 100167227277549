@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1215px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-800: 800;
  --color-white: #fff;
  --color-dark-500: #757471;
  --color-dark-600: #6a6a66;
  --color-dark-700: #424244;
  --color-dark-900: #393833;
  --color-blue-100: #e0e9fc;
  --color-blue: #648eef;
  --color-gray-100: #eceef2;
  --color-gray: #e7e7e7;
  --dark-700: invert(22%) sepia(9%) saturate(118%) hue-rotate(201deg) brightness(97%) contrast(84%);
  --dark-900: invert(17%) sepia(4%) saturate(1068%) hue-rotate(13deg) brightness(94%) contrast(83%);
  --radius-main: 16px;
  --arrow: url("data:image/svg+xml,<svg width=\"20\" height=\"16\" viewBox=\"0 0 20 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18.25 8H1.75M1.75 8L8.63235 2M1.75 8L8.63235 14\" stroke=\"%23000000\" stroke-width=\"2.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>");
  --puzzle: url("data:image/svg+xml,<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M24.4683 21.3495C23.5661 21.6704 22.5938 21.0011 22.5938 20.0229V11.345H15.8948C14.6811 11.345 14.0382 12.7841 14.847 13.6892C15.3084 14.2042 15.5625 14.8703 15.5625 15.5637C15.5625 17.1142 14.3004 18.3762 12.75 18.3762C11.1996 18.3762 9.9375 17.1142 9.9375 15.5637C9.9375 14.8703 10.1916 14.2042 10.653 13.6892C11.4618 12.7841 10.8189 11.345 9.60516 11.345H4.21875C1.89244 11.345 0 13.2374 0 15.5637V32.4387C0 34.765 1.89244 36.6575 4.21875 36.6575H7.125C7.125 39.7598 9.64772 42.2825 12.75 42.2825C15.8523 42.2825 18.375 39.7598 18.375 36.6575H22.5938V27.9797C22.5938 27.0144 23.598 26.3513 24.4683 26.6531C26.2151 27.2753 28.2173 25.8717 28.2188 24.0013C28.2188 22.1282 26.2178 20.726 24.4683 21.3495Z\" fill=\"%23000000\"/><path d=\"M43.7812 11.345H40.875C40.875 8.24269 38.3523 5.71997 35.25 5.71997C32.1477 5.71997 29.625 8.24269 29.625 11.345H25.4062V18.3762C28.5085 18.3762 31.0312 20.8989 31.0312 24.0012C31.0312 27.1035 28.5085 29.6262 25.4062 29.6262V36.6575H43.7812C46.1076 36.6575 48 34.765 48 32.4387V15.5637C48 13.2374 46.1076 11.345 43.7812 11.345Z\" fill=\"%23000000\"/></svg>");
  --check: url("data:image/svg+xml,<svg width=\"56\" height=\"34\" viewBox=\"0 0 56 34\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1.51221 18.1664C0.651542 19.1269 0.651541 20.5812 1.51221 21.5418L11.6817 32.8916C12.6893 34.0161 14.4512 34.013 15.4548 32.8849V32.8849C16.3099 31.9239 16.3073 30.4741 15.4489 29.5161L5.27938 18.1664C4.27421 17.0445 2.51738 17.0445 1.51221 18.1664V18.1664ZM54.4828 1.11554C53.4741 -0.0103262 51.7108 -0.00991391 50.7025 1.11642L30.1377 24.0896C28.547 25.8666 25.7646 25.8656 24.1752 24.0875L18.8629 18.1447C17.8601 17.0228 16.1067 17.0156 15.0947 18.1292V18.1292C14.2203 19.0915 14.2151 20.5592 15.0828 21.5276L24.1762 31.6764C25.766 33.4507 28.5446 33.4507 30.1344 31.6764L54.4828 4.50204C55.3463 3.53834 55.3463 2.07924 54.4828 1.11554V1.11554ZM40.9136 4.48634C41.772 3.52831 41.7745 2.07855 40.9195 1.11751V1.11751C39.9159 -0.0105127 38.1539 -0.0136073 37.1464 1.11088L25.265 14.3712C24.4021 15.3343 24.4047 16.7932 25.271 17.7532V17.7532C26.2777 18.8689 28.0294 18.8658 29.0322 17.7466L40.9136 4.48634Z\" fill=\"%23000000\"/></svg>");
  --heart: url("data:image/svg+xml,<svg width=\"48\" height=\"44\" viewBox=\"0 0 48 44\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1.9072e-07 13.2028C-0.00059624 9.65937 1.39772 6.26449 3.88035 3.78198C6.36298 1.29948 9.7214 -0.0621322 13.2 0.00348715C17.3216 -0.018805 21.2544 1.76138 24 4.89212C26.7456 1.76138 30.6784 -0.018805 34.8 0.00348715C38.2786 -0.0621322 41.637 1.29948 44.1196 3.78198C46.6023 6.26449 48.0006 9.65937 48 13.2028C48 26.2946 32.6904 36.1794 24 44.0012C15.3288 36.1134 1.9072e-07 26.3044 1.9072e-07 13.2028Z\" fill=\"%23000000\"/></svg>");
  --bulb: url("data:image/svg+xml,<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M22.5898 35.6934V24.9355L17.0168 19.3629C16.4535 18.7997 16.4535 17.8867 17.0168 17.3239C17.5796 16.7606 18.4926 16.7606 19.0558 17.3239L24.0319 22.2999L29.008 17.3239C29.5708 16.7606 30.4838 16.7606 31.047 17.3239C31.6099 17.8867 31.6099 18.7997 31.047 19.3629L25.4733 24.9363V35.6934H33.4578C33.7574 33.7162 34.7106 31.8661 36.1751 30.4785C37.8977 28.8459 39.2531 26.9211 40.2038 24.7572C41.1885 22.516 41.6877 20.129 41.6877 17.6622C41.6877 12.9835 39.876 8.57471 36.5867 5.24805C33.3 1.92432 28.9172 0.0632324 24.2461 0.00720215C24.1744 0.00646973 24.104 0.00610352 24.0326 0.00610352C14.4288 0.00610352 6.52848 7.75586 6.37724 17.3788C6.33805 19.8859 6.8134 22.3153 7.79118 24.6005C8.73381 26.8043 10.0943 28.7646 11.8345 30.4276C13.3257 31.8522 14.2969 33.7198 14.6031 35.6934H22.5898Z\" fill=\"%23000000\"/><path d=\"M14.7075 38.5769V41.1964C14.7075 44.9515 17.7621 48.0061 21.5168 48.0061H26.5468C30.3015 48.0061 33.3564 44.9515 33.3564 41.1964V38.5769H14.7075Z\" fill=\"%23000000\"/></svg>");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  color: var(--color-dark-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-variant-numeric: lining-nums;
  font-size: 16px;
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.logo {
  max-width: 245px;
  width: 100%;
  filter: var(--dark-900);
  transition: opacity .2s;
}

@media only screen and (max-width: 1180px) {
  .logo {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 992px) {
  .logo {
    max-width: 250px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .logo {
    max-width: 180px;
  }
}

.logo:hover {
  opacity: .7;
}

@media only screen and (max-width: 992px) {
  .logo:hover {
    opacity: 1;
  }
}

img {
  width: 100%;
  object-fit: cover;
}

* {
  border-radius: var(--radius-main);
}

.section {
  padding-block: 110px;
}

@media only screen and (max-width: 992px) {
  .section {
    padding-block: 3.125rem;
  }
}

.main--top {
  padding-top: 170px;
}

@media only screen and (max-width: 992px) {
  .main--top {
    padding-top: 120px;
  }
}

.link {
  transition: opacity .2s;
}

.link:hover {
  opacity: .5;
}

@media only screen and (max-width: 992px) {
  .link:hover {
    opacity: 1;
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-600);
  color: var(--color-dark-900);
  text-transform: capitalize;
  overflow: hidden;
}

h1, h2 {
  font-size: 64px;
}

@media only screen and (max-width: 768px) {
  h1, h2 {
    font-size: 46px;
  }
}

@media only screen and (max-width: 475px) {
  h1, h2 {
    font-size: 34px;
  }
}

h2 {
  margin-bottom: 50px;
  line-height: 1.2;
}

p {
  color: var(--color-dark-500);
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fw-400);
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  margin: 0 auto;
  padding-block: 24px;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 1180px) {
  .header {
    padding-block: 15px;
  }
}

.header .logo--tablet {
  display: none;
}

@media only screen and (max-width: 992px) {
  .header .logo--tablet {
    margin-right: auto;
    display: block;
  }
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header.scroll {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray);
  padding-block: 10px;
}

@media only screen and (max-width: 992px) {
  .header__btns {
    gap: 30px;
    margin: 0 auto;
  }
}

.nav {
  color: inherit;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner .logo--header {
    display: none;
  }

  .header .nav__inner {
    z-index: -1;
    color: inherit;
    padding-block: calc(var(--header-height) * 1.2) 20px;
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    margin: 0 auto 20px;
  }

  .nav__list:not(.nav__list--footer) {
    flex-direction: column;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 25px;
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-of-type) {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.nav__link {
  font-size: 16px;
  font-weight: var(--fw-500);
  color: var(--color-dark);
  white-space: nowrap;
  background-color: #0000;
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  .nav__link {
    padding: 10px 0;
  }
}

.nav .true {
  border-bottom: 1px solid var(--color-dark);
  font-weight: var(--fw-800);
}

.nav__btns {
  justify-self: end;
  align-items: center;
  gap: 10px;
  display: flex;
}

@media only screen and (max-width: 576px) {
  .nav__btns {
    flex-direction: column;
    gap: 20px;
  }
}

[class].btn {
  max-width: -moz-fit-content;
  max-width: fit-content;
  white-space: nowrap;
  border-radius: calc(var(--radius-main)  - 8px);
  font-weight: var(--fw-500);
  font-size: 16px;
  font-family: var(--font-family-primary);
  text-align: center;
  text-transform: capitalize;
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 19px 24px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
  background-color: var(--color-gray) !important;
  color: var(--color-dark-900) !important;
}

[class].btn--user:hover {
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}

@media only screen and (max-width: 992px) {
  [class].btn--user:hover {
    color: var(--color-dark-900) !important;
    background-color: var(--color-gray) !important;
  }
}

[class].btn--order {
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}

[class].btn--order:hover {
  background-color: var(--color-gray) !important;
  color: var(--color-dark-900) !important;
}

@media only screen and (max-width: 992px) {
  [class].btn--order:hover {
    background-color: var(--color-blue) !important;
    color: var(--color-white) !important;
  }
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-dark-900);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened .opened span .burger:before, .closed.opened .opened span .burger:after {
  background-color: var(--lines-color);
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  background-color: var(--lines-color);
  animation-name: toplinecross;
}

.opened span:after {
  background-color: var(--lines-color);
  animation-name: bottomlinecross;
}

.opened .logo:not(.logo--tablet) {
  display: none;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy h1 {
  margin: 0 auto 50px;
  font-size: 64px;
}

@media only screen and (max-width: 768px) {
  .policy h1 {
    font-size: 46px;
  }
}

@media only screen and (max-width: 475px) {
  .policy h1 {
    font-size: 34px;
  }
}

.policy p {
  font-size: 16px;
  line-height: 1.65;
}

.policy p:not(:last-child) {
  margin-bottom: 25px;
}

.policy > * {
  font-family: var(--font-family-secondary);
}

.policy__content:not(:last-child) {
  margin-bottom: 65px;
}

.policy h2 {
  margin-bottom: 1.25rem;
  font-size: 32px;
}

.policy ul {
  color: var(--color-dark-500);
  margin-bottom: 24px;
  padding-left: 15px;
}

.policy li {
  padding-left: 5px;
  font-size: 20px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  margin-right: 5px;
}

.policy a {
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 1180px) {
  .policy h2 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 768px) {
  .policy h2 {
    font-size: 28px;
  }
}

.footer__inner, .footer__list {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__inner {
  padding-block: 105px 24px;
}

@media only screen and (max-width: 992px) {
  .footer__inner {
    padding-block: 50px 24px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__inner {
    flex-direction: column-reverse;
    gap: 15px;
  }
}

.footer__inner p, .footer__inner a {
  color: var(--color-black);
  font-weight: var(--fw-400);
  font-size: 16px;
}

.footer__item:not(:last-child) {
  margin-right: 25px;
}

@media only screen and (max-width: 475px) {
  .footer .copyright {
    max-width: 170px;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
}

.grid {
  display: grid;
}

.grid__item {
  justify-content: space-between;
  position: relative;
}

.grid[data-items="1"] {
  --column: 2;
  grid-template-columns: repeat(var(--column), 1fr);
  gap: 25px;
}

@media only screen and (max-width: 768px) {
  .grid[data-items="1"] {
    --column: 1;
  }
}

.grid[data-items="2"] {
  --column: 2;
  grid-template-columns: repeat(var(--column), 1fr);
  gap: 25px;
}

@media only screen and (max-width: 768px) {
  .grid[data-items="2"] {
    --column: 1;
  }
}

.grid[data-items="2"] .grid__item:nth-child(1) {
  grid-row: 1 / 3;
}

@media only screen and (max-width: 768px) {
  .grid[data-items="2"] .grid__item:nth-child(1) {
    grid-row: auto;
  }
}

.grid[data-items="2"] .grid__item:nth-child(4) {
  grid-column: 1 / 3;
}

@media only screen and (max-width: 768px) {
  .grid[data-items="2"] .grid__item:nth-child(4) {
    grid-column: auto;
  }
}

.grid[data-items="3"] {
  --column: 2;
  grid-template-columns: repeat(var(--column), 1fr);
  gap: 25px;
}

@media only screen and (max-width: 576px) {
  .grid[data-items="3"] {
    --column: 1;
  }
}

.grid[data-items="3"] .grid__item:nth-child(1) {
  grid-row: 1 / 4;
}

@media only screen and (max-width: 768px) {
  .grid[data-items="3"] .grid__item:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
}

@media only screen and (max-width: 576px) {
  .grid[data-items="3"] .grid__item:nth-child(1) {
    grid-area: auto;
  }
}

@media only screen and (max-width: 768px) {
  .grid[data-items="3"] .grid__item:nth-child(4) {
    grid-column: 1 / 3;
  }
}

@media only screen and (max-width: 576px) {
  .grid[data-items="3"] .grid__item:nth-child(4) {
    grid-column: auto;
  }
}

.grid[data-items="3"] .grid__item:nth-child(5) {
  grid-column: 1 / 3;
}

@media only screen and (max-width: 768px) {
  .grid[data-items="3"] .grid__item:nth-child(5) {
    grid-column: 1 / 3;
  }
}

@media only screen and (max-width: 576px) {
  .grid[data-items="3"] .grid__item:nth-child(5) {
    grid-column: auto;
  }
}

.hero {
  padding-block: 170px 112px;
}

@media only screen and (max-width: 768px) {
  .hero {
    padding-block: 120px 50px;
  }
}

.hero__wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hero h1 {
  max-width: 1010px;
  width: 100%;
  margin-bottom: 16px;
  line-height: 1.17;
}

.hero p {
  max-width: 600px;
  width: 100%;
  margin-bottom: 50px;
  font-size: 18px;
}

.hero__image img {
  width: 100%;
  aspect-ratio: 97 / 50;
}

@media only screen and (max-width: 768px) {
  .hero__image img {
    aspect-ratio: 16 / 10;
  }
}

.rules h2 {
  line-height: 1.16;
}

.rules__item:not(:last-child) {
  margin-bottom: 50px;
}

.rules .article {
  padding: 30px;
}

@media only screen and (max-width: 992px) {
  .rules .article {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .rules .article {
    padding: 10px;
  }
}

.rules .article__content {
  max-width: 550px;
}

@media only screen and (max-width: 768px) {
  .rules .article__content {
    max-width: 100%;
  }
}

.rules .article__content h3 {
  margin-bottom: 10px;
  line-height: 1.2;
}

.rules .article__image {
  max-width: 550px;
}

@media only screen and (max-width: 768px) {
  .rules .article__image {
    max-width: 100%;
  }
}

.rules .article__image img {
  aspect-ratio: 58 / 39;
}

@media only screen and (max-width: 768px) {
  .rules .article__image img {
    aspect-ratio: 16 / 9;
  }
}

.product {
  border: 1px solid var(--color-dark-600);
  padding: 24px;
}

@media only screen and (max-width: 768px) {
  .product {
    padding: 10px;
  }
}

.product__image {
  margin-bottom: 15px;
}

.product img {
  aspect-ratio: 72 / 25;
}

@media only screen and (max-width: 768px) {
  .product img {
    aspect-ratio: 16 / 9;
  }
}

.product__content {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .product__content {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.product__deskr {
  max-width: 790px;
  width: 100%;
  margin-right: 30px;
}

@media only screen and (max-width: 768px) {
  .product__deskr {
    margin-right: 0;
  }
}

.product h3 {
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 1.16;
}

.product__order {
  align-items: center;
  gap: 40px;
  display: flex;
}

.product span {
  font-size: 32px;
  font-weight: var(--fw-600);
}

.article {
  border: 1px solid var(--color-dark-600);
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .article {
    flex-direction: column-reverse;
    gap: 30px;
    padding: 10px;
  }
}

.article__content {
  max-width: 420px;
  width: 100%;
  margin-right: 30px;
}

@media only screen and (max-width: 768px) {
  .article__content {
    max-width: 100%;
    margin-right: 0;
  }
}

.article__image {
  max-width: 700px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .article__image {
    max-width: 100%;
  }
}

.article img {
  aspect-ratio: 30 / 19;
}

.article h3 {
  margin-bottom: 10px;
  font-size: 32px;
}

.article__order {
  align-items: center;
  gap: 40px;
  display: flex;
}

.article span {
  font-size: 32px;
  font-weight: var(--fw-600);
}

.articles h2 {
  line-height: 1.2;
}

.articles__item:not(:last-child) {
  margin-bottom: 25px;
}

.info {
  height: 100%;
  border: 1px solid var(--color-dark-600);
  flex-direction: column;
  padding: 130px 25px 35px;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 475px) {
  .info {
    padding: 120px 15px 25px;
  }
}

.info:before, .info:after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.info:before {
  width: 80px;
  height: 80px;
  background-color: var(--color-gray-100);
  border-radius: calc(var(--radius-main) * 4);
  top: 24px;
  left: 24px;
}

@media only screen and (max-width: 475px) {
  .info:before {
    top: 15px;
    left: 15px;
  }
}

.info:after {
  width: 48px;
  height: 48px;
  filter: var(--dark-700);
  top: 40px;
  left: 40px;
}

@media only screen and (max-width: 475px) {
  .info:after {
    top: 31px;
    left: 31px;
  }
}

.info--itinerary:after {
  background-image: var(--puzzle);
}

.info--inclusions:after {
  background-image: var(--check);
}

.info--redefined:after {
  background-image: var(--heart);
}

.info--sustainable:after {
  background-image: var(--bulb);
}

.info h3 {
  letter-spacing: .06rem;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 1.1;
}

.info--home {
  padding: 130px 25px 25px;
}

@media only screen and (max-width: 475px) {
  .info--home {
    padding: 120px 15px 25px;
  }
}

.info--home h3 {
  line-height: 1;
}

.offer {
  background-color: var(--color-blue-100);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding: 24px;
  display: flex;
}

@media only screen and (max-width: 475px) {
  .offer {
    padding: 15px;
  }
}

.offer h3 {
  font-size: 32px;
  line-height: 1.2;
}

.offer__inner {
  flex-wrap: wrap;
  gap: 10px 50px;
  display: flex;
}

.offer span {
  color: var(--color-dark-500);
  font-size: 24px;
  line-height: 1;
  font-weight: var(--fw-600);
  white-space: nowrap;
  display: inline-block;
}

.offer__content {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.offer p {
  font-size: 20px;
}

.offer--home p {
  color: var(--color-dark-900);
  font-size: 32px;
  line-height: 1.2;
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 992px) {
  .offer--home p {
    font-size: 26px;
  }
}

@media only screen and (max-width: 576px) {
  .offer--home p {
    font-size: 22px;
  }
}

.about__item:not(:last-child) {
  margin-bottom: 50px;
}

.about img {
  aspect-ratio: 21 / 8;
}

@media only screen and (max-width: 992px) {
  .about img {
    aspect-ratio: 21 / 10;
  }
}

.slider {
  padding-block: 5px;
}

.slider h3 {
  color: var(--color-dark-600);
  font-size: 16px;
}

.slider h3, .slider p {
  display: inline;
}

.slider .article__image {
  position: relative;
}

.slider .article__image img {
  aspect-ratio: 117 / 99;
}

@media only screen and (max-width: 768px) {
  .slider .article__image img {
    aspect-ratio: 16 / 10;
  }
}

.slider .slide-pagination {
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: var(--color-white);
  gap: 16px;
  padding: 8px 16px;
  line-height: 1;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.slider .swiper-pagination-bullet {
  height: 8px;
  width: 8px;
  background-color: var(--color-dark-900);
  margin: 0;
}

.slider .slide-pagination .swiper-pagination-bullet {
  margin: 0;
}

.slider .swiper-button-next, .slider .swiper-button-prev {
  width: 48px;
  height: 48px;
  border-radius: calc(var(--radius-main) * 8 - 4px);
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
}

@media only screen and (max-width: 992px) {
  .slider .swiper-button-next, .slider .swiper-button-prev {
    display: none;
  }
}

.slider .swiper-button-next:before, .slider .swiper-button-prev:before {
  content: "";
  width: 20px;
  height: 20px;
  filter: var(--dark-900);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: var(--arrow);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%)translateX(-50%)rotate(180deg);
}

.slider .swiper-button-next {
  right: 25px;
}

.slider .swiper-button-prev {
  left: 25px;
}

.slider .swiper-button-prev:before {
  content: "";
  transform: translateY(-50%)translateX(-50%);
}

.posts h2 {
  line-height: 1.17;
}

.posts__item:not(:last-child) {
  margin-bottom: 25px;
}

.post {
  height: 100%;
  max-width: 600px;
  width: 100%;
  border: 1px solid var(--color-dark-600);
  flex-direction: column;
  padding: 24px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .post {
    max-width: 100%;
    padding: 10px;
  }
}

.post__image {
  margin-bottom: 15px;
}

.post img {
  aspect-ratio: 48 / 25;
}

.post h3 {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.1;
}

.post p {
  flex-grow: 1;
  margin-bottom: 50px;
}

/*# sourceMappingURL=main.css.map */
