@use "../../utils/functions/"as*;
@use "../../utils/mixins"as*;

.post {
  @include flex-col;
  height: 100%;
  max-width: 600px;
  width: 100%;
  border: 1px solid var(--color-dark-600);
  padding: 24px;

  @include media(mobile-l) {
    max-width: 100%;
    padding: 10px;
  }

  &__image {
    margin-bottom: 15px;
  }

  img {
    aspect-ratio: 48/25;
  }

  h3 {
    font-size: 24px;
    line-height: 1.1;
    margin-bottom: 10px;
  }

  p {
    flex-grow: 1;
    margin-bottom: 50px;
  }
}