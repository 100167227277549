@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.grid {
  $root: &;
  display: grid;

  &__item {
    position: relative;
    justify-content: space-between;
  }

  &[data-items="1"] {
    --column: 2;
    gap: 25px;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(mobile-l) {
      --column: 1;
    }
  }

  &[data-items="2"] {
    --column: 2;
    gap: 25px;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(mobile-l) {
      --column: 1;
    }

    .grid__item {
      &:nth-child(1) {
        grid-row: 1/3;

        @include media(mobile-l) {
          grid-row: auto;
        }
      }

      &:nth-child(4) {
        grid-column: 1/3;

        @include media(mobile-l) {
          grid-column: auto;
        }
      }
    }
  }

  &[data-items="3"] {
    --column: 2;
    gap: 25px;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(mobile-m) {
      --column: 1;
    }

    .grid__item {
      &:nth-child(1) {
        grid-row: 1/4;

        @include media(mobile-l) {
          grid-row: 1/2;
          grid-column: 1/3;
        }

        @include media(mobile-m) {
          grid-row: auto;
          grid-column: auto;
        }
      }

      &:nth-child(4) {
        @include media(mobile-l) {
          grid-column: 1/3;
        }

        @include media(mobile-m) {
          grid-column: auto;
        }
      }

      &:nth-child(5) {
        grid-column: 1/3;

        @include media(mobile-l) {
          grid-column: 1/3;
        }

        @include media(mobile-m) {
          grid-column: auto;
        }
      }
    }
  }
}